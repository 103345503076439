import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Img from "gatsby-image";
import styled from 'styled-components';

const BlogPost = ({ data }) => {
  const { title, body , tags, thumbnail } = data.contentfulBlogPost;
  return (
    <Layout>
      <SEO title={title} />
      <MainContent>
        <div className="pl-Content__Wrap">
          <Headline>
            {title}
          </Headline>
          <MainContentImage>
              <Img fluid={thumbnail.fluid} />
          </MainContentImage>
          <MainContentText>{body.body}</MainContentText>
          <TagList>
              {tags.map(tag => (
                <TagListItem>
                  {tag}
                </TagListItem>
              ))}
          </TagList>
        </div>
      </MainContent>
    </Layout>
  );
};

const MainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 980px;
    width: 100%;
    margin: 1em auto 0;

    & a {
        text-decoration: none;
        color: #000;
    }

    @media (max-width: 768px) {
      
    }
`

const MainContentImage = styled.div`
    width: 100%;
    box-sizing: border-box;
`

const MainContentText = styled.p`
  color: initial;
  font-size: 0.8rem;
  font-weight: 100;
  text-align: center;
  margin: 2rem 0 1.2rem;
  white-space: pre-wrap;
  line-height: 2.2;
`

const Headline = styled.h1`
    font-size: 2rem;
    font-weight: 100;
    text-align: center;
    margin: 0.2rem 0 0.5em;
`

const TagList = styled.ul`
  display: flex;
  justify-content: center;
  list-style: none;
  padding: 0;
  margin: 1.4em 0 0.5em;
`

const TagListItem = styled.li`
    width: calc(50% - 20px);
    text-align: center;
    background: #a3c7fc;
    padding: 0.3em;
    margin-right: 0.7em;
    border-radius: 7px;
    color: #000;
`

export default BlogPost;
export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      title
      slug
      body {
        body
      }
      thumbnail {
        fluid(maxWidth: 1000, quality: 90) {
            ...GatsbyContentfulFluid_tracedSVG
        }
      }
      tags
    }
  }
`;